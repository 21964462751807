import Page from "../components/Page";
import OnigiriMenu from "../components/OnigiriMenu";

function Onigiri() {
  return (
    <Page imageSrc="/onigiri_.png" text="onigiri">
      <div className="onigiri_page">
        <OnigiriMenu />
      </div>
    </Page>
  );
}

export default Onigiri;
