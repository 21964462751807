import Page from "../components/Page";
import UmeSupermarket from "../components/UmeSupermarket";

function Rice() {
  return (
    <Page imageSrc="/rice.png" text="rice">
      <UmeSupermarket />
    </Page>
  );
}

export default Rice;
