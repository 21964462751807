import "./Header.css";
import NavBar from "./Navbar";

function Header() {
  return (
    <header className="header">
      <div className="center-container">
        <a href="/">
          <img src="logo.svg" alt="Rice Kitchen Come logo" className="logo" />
        </a>
      </div>
      <NavBar />
    </header>
  );
}

export default Header;
