import Page from "../components/Page";
import UmeSupermarket from "../components/UmeSupermarket";
function Sake() {
  return (
    <Page imageSrc="/sake.png" text="Sake">
      <UmeSupermarket />
    </Page>
  );
}

export default Sake;
