import OnigiriCard from "./OnigiriCard";

const OnigiriMenu = () => {
  return (
    <section className="onigiri-list">
      <div className="vertical-text">おにぎり</div>

      {[
        "Salmon",
        "Teriyaki",
        "Beef & Mustard",
        "Natto",
        "Tempura",
        "Tuna",
        "Green Chill",
      ].map((item) => (
        <OnigiriCard key={item} name={item} />
      ))}
    </section>
  );
};

export default OnigiriMenu;
