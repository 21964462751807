import Page from "../components/Page";

function ContactUs() {
  return (
    <Page imageSrc="/contact-us.png" text="Contact us">
      <div className="contact-us">
        <br />
        <p>Whether you need assistance, have a question about our products,</p>
        <p>
          or simply want to share your feedback, our team is ready to assist.
        </p>
        <br />
        <p>
          📧 Contact us by email:{" "}
          <a href="mailto:info@comefood.dk">info@comefood.dk</a>
        </p>
        <br />

        <p>
          We strive to respond as quickly as possible to ensure you have the
          best experience. Reach out anytime!
        </p>
      </div>
    </Page>
  );
}

export default ContactUs;
