import React from "react";
import "./Page.css"; // Add styles for the OnigiriCard component here
import Header from "./Header";
import Footer from "./Footer";

const Page = ({ imageSrc, text, children }) => {
  return (
    <div>
      <Header />
      <div className="full-width">
        <div
          className="page-image"
          style={{ backgroundImage: `url(${imageSrc})` }}
        >
          <div className="page-text">{text ? text.toUpperCase() : ""}</div>
        </div>
      </div>
      <div className="page-content">{children}</div>
      <Footer />
    </div>
  );
};

export default Page;
