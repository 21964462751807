import Page from "../components/Page";

function Catering() {
  return (
    <Page imageSrc="/contact-us.png" text="Contact us">
      <div className="contact-us">
        <br />

        <h2>Bring Our Flavor to Your Next Event!</h2>
        <br />

        <p>
          Make your gathering truly unforgettable with our delicious catering
          services!{" "}
        </p>
        <p>
          We offer a variety of our Onigiri to meet the needs of any event –
          from intimate gatherings to large celebrations.{" "}
        </p>
        <p>
          Whether it’s a corporate function, a wedding, or a special occasion,{" "}
        </p>
        <p>let us handle the food so you can focus on making memories. </p>
        <br />
        <p>
          📧 Contact us to discuss your catering needs:{" "}
          <a href="mailto:info@comefood.dk">info@comefood.dk</a>
        </p>
        <br />

        <p>
          We’re excited to bring the taste of come rice kitchen straight to your
          table!
        </p>
      </div>
    </Page>
  );
}

export default Catering;
