import React from "react";
import "./OnigiriCard.css"; // Add styles for the OnigiriCard component here

function OnigiriCard({ name }) {
  return (
    <div className="onigiri-item">
      <img src={`${name}.jpg`} alt={`${name} Onigiri`} />
      <div className="onigiri-label">{name}</div>
    </div>
  );
}

export default OnigiriCard;
