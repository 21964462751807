// Let's create this page together in React. Below is an outline to help us get started, focusing on the different components and layout:

import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css"; // Add custom styles here to achieve the desired look
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import Supermarket from "./pages/Supermarket";
import Openinghours from "./pages/Openinghours";
import Catering from "./pages/Catering";
import Onigiri from "./pages/Onigiri";
import Sake from "./pages/Sake";
import Rice from "./pages/Rice";

function App() {
  return (
    <div className="app">
      <Router>
        <Switch>
          <Route exact path="/">
            <div className="app">
              {/* Header Section */}
              <Header />
              <Home />
              <Footer />
            </div>
          </Route>
          <Route path="/contactus" component={ContactUs} />
          <Route path="/supermarket" component={Supermarket} />
          <Route path="/openinghours" component={Openinghours} />
          <Route path="/catering" component={Catering} />
          <Route path="/onigiri" component={Onigiri} />
          <Route path="/sake" component={Sake} />
          <Route path="/rice" component={Rice} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

/* 
In App.css, make sure to add styles for:
- header, onigiri-display, process-section, footer, nav, etc.
- Styling for the fonts, colors, and layout similar to the reference image.
*/
